import { DOMAttributes } from 'react';
import classNames from 'classnames';
import { NavigationItemFragment } from '@amf/shared/types/graphql';
import useCurrentPage from '@amf/shared/hooks/useCurrentPage';
import NavigationMenuItem from '@amf/shared/components/navigation/NavigationMenuItem';

interface NavigationMenuDropdownProps extends DOMAttributes<HTMLAnchorElement> {
  item: NavigationItemFragment;
  className?: string;
  selectedClassName?: string;
  childClassName?: string;
  childSelectedClassName?: string;
}

export default function NavigationMenuDropdown({
  item,
  className,
  selectedClassName,
  childClassName,
  childSelectedClassName,
  ...props
}: NavigationMenuDropdownProps) {
  const current = useCurrentPage(item.path, item.children?.map(c => c.path) ?? []);
  if (!item.children || item.children.length === 0) {
    return null;
  }

  return (
    <div className='Navigation__menuSubmenu'>
      <a className={classNames(className, current && selectedClassName)}>{item.title}</a>
      <div className='Navigation__submenu'>
        {item.children.map(child => (
          <NavigationMenuItem
            key={child.id}
            item={child}
            className={childClassName}
            selectedClassName={childSelectedClassName}
            {...props}
          />
        ))}
      </div>
    </div>
  );
}
