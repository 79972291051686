import { NavigationItem, PartnerFragment } from '@amf/shared/types/graphql';
import { sdk } from '@amf/shared/lib/graphQlClient';

import { isValidURL } from './links';

export enum NavigationType {
  category = 'category',
  page = 'page',
  link = 'link',
}

export function isExternalLink(link: string): boolean {
  return isValidURL(link);
}

export interface NavigationProps {
  navigation: NavigationItem[];
  partners: PartnerFragment[];
}

export async function getNavigation(locale: string) {
  const { navigation } = await sdk.Navigation({
    locale,
    union: process.env.NEXT_PUBLIC_UNION,
  });

  return navigation;
}

export async function getPartners() {
  const { partnerList: partners } = await sdk.PartnerList({
    union: process.env.NEXT_PUBLIC_UNION,
  });

  return partners;
}
