export default function BrandPlzen() {
  return (
    <svg
      width='223'
      height='79'
      viewBox='0 0 223 79'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M92.0141 13.59V15.9071C92.0141 18.3401 90.7434 19.5798 88.2598 19.5798H84.0203V24.7471H80.3006V9.91726H88.2598C90.7434 9.91726 92.0141 11.1685 92.0141 13.59ZM88.2829 14.1924C88.2829 13.3698 87.9941 13.1034 87.1277 13.1034H83.9972V16.3937H87.1277C87.971 16.3937 88.2829 16.1041 88.2829 15.2931V14.1924Z'
        fill='#4A4A4A'
      />
      <path d='M104.178 21.5494V24.7239H93.9319V9.91726H97.6631V21.5494H104.178Z' fill='#4A4A4A' />
      <path
        d='M117.382 21.5494V24.7239H105.646V21.6537L112.461 13.1034H106.027V9.91726H117.278V12.9759L110.474 21.5494H117.382Z'
        fill='#4A4A4A'
      />
      <path
        d='M123.089 13.0918V15.5711H128.957V18.7457H123.089V21.5494H129.835V24.7239H119.357V9.91726H129.835V13.0918H123.089Z'
        fill='#4A4A4A'
      />
      <path
        d='M144.413 9.91745V24.7473H140.786L135.507 16.1274V24.7473H131.787V9.91745H135.391L140.682 18.5373V9.91745H144.413ZM138.118 8.38813L133.231 6.43012L134.248 3.80014L138.141 5.45692L142.01 3.80014L143.027 6.43012L138.118 8.38813Z'
        fill='#4A4A4A'
      />
      <path
        d='M146.597 21.2713C146.578 21.1718 146.578 21.0696 146.597 20.9701L150.293 20.5183V20.6689C150.293 21.4683 150.582 21.7695 151.448 21.7695H153.909C154.752 21.7695 155.064 21.4683 155.064 20.6689V20.275C155.064 19.5798 154.821 19.4176 153.574 19.2322L150.201 18.734C147.636 18.3633 146.643 17.448 146.643 15.3278V13.393C146.643 10.9715 147.925 9.72028 150.409 9.72028H155.029C157.513 9.72028 158.772 10.9716 158.772 13.3814C158.781 13.4855 158.781 13.5901 158.772 13.6942L155.064 14.1229V14.007C155.064 13.1728 154.775 12.9064 153.909 12.9064H151.598C150.709 12.9064 150.443 13.196 150.443 14.007V14.3198C150.443 15.0266 150.64 15.154 151.945 15.3394L155.341 15.8376C157.929 16.2083 158.807 17.1236 158.807 19.3133V21.2713C158.807 23.6928 157.548 24.944 155.064 24.944H150.362C147.879 24.944 146.597 23.6928 146.597 21.2713Z'
        fill='#4A4A4A'
      />
      <path
        d='M166.026 18.8267H164.744V24.7239H161.013V9.91726H164.744V15.6291H166.026L169.769 9.91726H174.101L169.296 17.2511L174.344 24.7239H170L166.026 18.8267Z'
        fill='#4A4A4A'
      />
      <path
        d='M184.602 19.6724V24.747H180.859V19.6955L175.58 9.94029H179.715L182.742 15.6289L185.757 9.91712H189.904L184.602 19.6724ZM180.94 8.65427L179.923 6.03588L186.196 3.54492L187.213 6.16333L180.94 8.65427Z'
        fill='#4A4A4A'
      />
      <path
        d='M95.4685 32.4168V47.2234H91.7373V38.6036L88.5144 43.713H87.209L83.9861 38.5804V47.2234H80.2549V32.4168H83.859L87.8675 38.7774L91.8644 32.4168H95.4685Z'
        fill='#4A4A4A'
      />
      <path
        d='M107.32 44.049H102.099L101.152 47.2234H97.3396L101.96 32.4168H107.401L112.022 47.2234H108.187L107.32 44.049ZM106.362 40.8513L104.733 35.3712L103.069 40.8513H106.362Z'
        fill='#4A4A4A'
      />
      <path d='M124.209 44.049V47.2234H113.962V32.4168H117.682V44.049H124.209Z' fill='#4A4A4A' />
      <path
        d='M131.925 42.1835V47.2233H128.171V42.1835L122.903 32.4166H127.039L130.066 38.1053L133.057 32.4166H137.239L131.925 42.1835ZM128.263 31.1538L127.247 28.5354L133.52 26.0444L134.536 28.6629L128.263 31.1538Z'
        fill='#4A4A4A'
      />
      <path
        d='M146.758 35.6029V38.5225H152.638V41.697H146.758V47.2234H143.027V32.4168H153.505V35.6029H146.758Z'
        fill='#4A4A4A'
      />
      <path
        d='M167.528 35.8925V43.7593C167.528 46.1807 166.258 47.432 163.774 47.432H159.153C156.67 47.432 155.41 46.1807 155.41 43.7593V35.8925C155.41 33.4595 156.67 32.2198 159.153 32.2198H163.774C166.258 32.2198 167.528 33.4595 167.528 35.8925ZM162.665 35.3943H160.239C159.384 35.3943 159.084 35.6608 159.084 36.495V43.1568C159.084 43.9678 159.373 44.3154 160.239 44.3154H162.653C163.497 44.3154 163.809 44.0257 163.809 43.1568V36.495C163.809 35.6608 163.52 35.3943 162.653 35.3943'
        fill='#4A4A4A'
      />
      <path
        d='M181.413 35.6029H177.208V47.2234H173.489V35.6029H169.284V32.4168H181.413V35.6029Z'
        fill='#4A4A4A'
      />
      <path
        d='M195.102 42.3806V43.5392C195.102 45.9606 193.82 47.2119 191.348 47.2119H183.262V32.4168H191.025C193.508 32.4168 194.79 33.6681 194.79 36.0895V36.9468C194.813 37.4588 194.69 37.9668 194.437 38.4118C194.183 38.8568 193.809 39.2205 193.358 39.461C193.934 39.68 194.419 40.0872 194.736 40.617C195.052 41.1468 195.181 41.7681 195.102 42.3806ZM189.904 35.5565H186.981V38.1749H189.904C190.77 38.1749 191.059 37.92 191.059 37.109V36.634C191.059 35.823 190.77 35.5565 189.904 35.5565ZM191.371 42.369C191.371 41.5348 191.082 41.2104 190.216 41.2104H186.981V44.0258H190.216C191.082 44.0258 191.371 43.7593 191.371 42.9251V42.369Z'
        fill='#4A4A4A'
      />
      <path
        d='M206.111 44.049H200.89L199.943 47.2234H196.154L200.774 32.4168H206.215L210.836 47.2234H207.001L206.111 44.049ZM205.152 40.8513L203.524 35.3712L201.86 40.8513H205.152Z'
        fill='#4A4A4A'
      />
      <path d='M223 44.049V47.2234H212.753V32.4168H216.473V44.049H223Z' fill='#4A4A4A' />
      <path d='M66.0809 0.89209H33.1237V32.9384H66.0809V0.89209Z' fill='#1EA538' />
      <path d='M33.1237 32.9384L0.189453 32.9388V55.4963H33.1235L33.1237 32.9384Z' fill='#1EA538' />
      <path d='M9.43121 61.8916H33.1239V78.0422L9.43121 61.8916Z' fill='#1EA538' />
      <path d='M33.1235 38.9858H0.189453V45.3812H33.1235V38.9858Z' fill='white' />
      <path d='M33.1235 55.4961V61.8915H9.43086L0.189453 55.5888V55.4961H33.1235Z' fill='#FEC818' />
      <path d='M33.1351 0.89209H0.189453V32.9384H33.1351V0.89209Z' fill='#BB2E1C' />
      <path d='M33.1359 32.9036V78.0303L66.07 55.6233V32.9036H33.1359Z' fill='#BB2E1C' />
    </svg>
  );
}
