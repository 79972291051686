export enum OgImageType {
  default = 'default',
  matchDetail = 'matchDetail',
}

export function ogImageUrl(
  locale: string,
  type: OgImageType,
  props?: Record<string, string>,
): string {
  const params = new URLSearchParams({
    locale,
    type,
    ...props,
  });

  return `${process.env.NEXT_PUBLIC_BASE_URL}og-image.png?${params.toString()}`;
}

export type OgColorPalette = {
  primary: string;
  secondary: string;
  accent: string;
  textPrimary: string;
  textSecondary: string;
};

export const ogColorPalette: Record<string, OgColorPalette> = {
  plzen: {
    primary: '#1EA538',
    secondary: '#107622',
    accent: '#fec81a',
    textPrimary: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.4)',
  },
  ostrava: {
    primary: '#257AD4',
    secondary: '#1655a0',
    accent: '#FFFFFF',
    textPrimary: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.4)',
  },
  olomouc: {
    primary: '#2f2f2f',
    secondary: '#4A4A4A',
    accent: '#FFFFFF',
    textPrimary: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.4)',
  },
  jihlava: {
    primary: '#2f2f2f',
    secondary: '#4A4A4A',
    accent: '#FFFFFF',
    textPrimary: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.4)',
  },
  brno: {
    primary: '#ba2c1b',
    secondary: '#7d190c',
    accent: '#FFC81A',
    textPrimary: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.4)',
  },
  default: {
    primary: '#050b6d',
    secondary: '#000555',
    accent: '#FFC81A',
    textPrimary: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.4)',
  },
};

export function getOgColorPalette(): OgColorPalette {
  const id = process.env.NEXT_PUBLIC_UNION;

  // Brno
  if (
    id === 'ef52b863-ad0c-4a4b-9408-9064921ff412' ||
    id === '952bd190-5a28-473c-b845-b2b3a3166a08'
  ) {
    return ogColorPalette['brno'];
  }

  // Jihlava
  if (
    id === '766e9c29-8c89-4a0b-84ea-4d053f7d8d86' ||
    id === 'f6d16611-f4c6-479f-be47-2c268f56f377'
  ) {
    return ogColorPalette['jihlava'];
  }

  // Olomouc
  if (
    id === '2c1ef8d0-9d62-47a5-986d-860afe92a986' ||
    id === '51d63616-06de-41a6-bab2-811210c4100e'
  ) {
    return ogColorPalette['olomouc'];
  }

  // Ostrava
  if (
    id === 'c6bc868b-b3f8-4c4b-a721-f2e1e6f844bc' ||
    id === '7d630880-52ad-4b22-8604-63c654f26d8d'
  ) {
    return ogColorPalette['ostrava'];
  }

  // Plzeň
  if (
    id === '10dc6b0d-307d-467d-95ef-aadfe81ae442' ||
    id === '5617db91-e3a6-491d-b342-334113703d7a'
  ) {
    return ogColorPalette['plzen'];
  }

  return ogColorPalette['default'];
}
