import { forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

interface ContainerProps {
  position?: 'left' | 'center';
  children: ReactNode;
  className?: string;
}

// eslint-disable-next-line react/display-name
const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ children, className, position }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames('Container', className, {
          'Container--center': position === 'center',
        })}
      >
        {children}
      </div>
    );
  },
);

export default Container;
