export function getLanguageCountry(code: string) {
  if (code === 'cz') {
    return 'cz';
  }

  return 'us';
}

export function getLanguageName(code: string) {
  if (code === 'cz') {
    return 'Česky';
  }

  return 'English';
}

export function getLanguageLocale(code: string) {
  if (code === 'cz') {
    return 'cs_CZ';
  }

  return 'en_US';
}
