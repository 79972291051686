import Container from '@amf/shared/components/layout/Container';
import { useTranslate } from '@tolgee/react';
import { formatDate } from '@amf/shared/utils/date';

function Copyright() {
  const { t } = useTranslate();
  const date = new Date();
  return (
    <div className='Copyright'>
      <div className='Copyright__icon' />
      <Container>
        <div className='Copyright__content'>
          <p className='Copyright__about'>
            &copy; {formatDate(date, 'yyyy')} Asociace malého fotbalu
            {process.env.NEXT_PUBLIC_COOKIE_SCRIPT_KEY && (
              <>
                {' '}
                &bull;&nbsp;
                <a href='/cookies'>{t('cookies.title')}</a>
              </>
            )}
          </p>
          <a
            href='https://elasticle.cz'
            target='_blank'
            className='Copyright__author'
            rel='noreferrer'
            title='Elasticle'
          >
            Elasticle
          </a>
        </div>
      </Container>
    </div>
  );
}

export default Copyright;
