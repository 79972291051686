import classNames from 'classnames';
import Link from 'next/link';
import { DOMAttributes } from 'react';
import { useRouter } from 'next/router';
import { NavigationItemFragment } from '@amf/shared/types/graphql';
import { isExternalLink, NavigationType } from '@amf/shared/utils/navigation';
import useCurrentPage from '@amf/shared/hooks/useCurrentPage';
import NavigationMenuDropdown from '@amf/shared/components/navigation/NavigationMenuDropdown';
import { isValidURL } from '@amf/shared/utils/links';

interface NavigationMenuItemProps extends DOMAttributes<HTMLAnchorElement> {
  item: NavigationItemFragment;
  className?: string;
  selectedClassName?: string;
}

export default function NavigationMenuItem({
  item,
  className,
  selectedClassName,
  ...props
}: NavigationMenuItemProps) {
  let path = isValidURL(item.path) ? item.path : item.path.replace('//', '/');
  if (path.startsWith('/', 0)) {
    path = path.substring(1);
  }

  const current = useCurrentPage(path);
  const { locale } = useRouter();

  if (!process.env.NEXT_PUBLIC_CNP && item.path === 'cnp') {
    return null;
  }

  if (item.type === NavigationType.category) {
    return (
      <NavigationMenuDropdown
        item={item}
        className={className}
        selectedClassName={selectedClassName}
        childClassName={'Navigation__submenuItem'}
        childSelectedClassName={'Navigation__submenuItem--selected'}
        {...props}
      />
    );
  }

  const external = isExternalLink(path);

  return (
    <Link
      href={external ? path : `/${path}`}
      passHref={item.type === NavigationType.link && isExternalLink(path)}
      locale={locale}
    >
      <a
        className={classNames(className, current && selectedClassName)}
        target={external ? '_blank' : undefined}
        rel={external ? 'noreferrer' : undefined}
        {...props}
      >
        {item.title}
      </a>
    </Link>
  );
}
