import Script from 'next/script';
import { useCallback, useRef } from 'react';

interface CookieScriptProps {
  cookieKey?: string | null;
}

function CookieScript({ cookieKey }: CookieScriptProps) {
  if (!cookieKey) return null;
  return (
    <>
      <Script src={`//cdn.cookie-script.com/s/${cookieKey}.js`} charSet='UTF-8' />
    </>
  );
}

export default CookieScript;

interface CookieScriptManageProps {
  cookieKey?: string | null;
}

export function CookieScriptManage({ cookieKey }: CookieScriptManageProps) {
  const ref = useRef<HTMLDivElement | null>(null);

  const moveContent = useCallback(
    (event: { target?: HTMLScriptElement }) => {
      const content = event.target?.nextElementSibling;
      if (!content || !ref.current) return;

      ref.current.innerHTML = content.innerHTML;
      content.innerHTML = null;
    },
    [ref],
  );

  if (!cookieKey) return null;

  return (
    <>
      <Script
        src={`https://report.cookie-script.com/r/${cookieKey}.js`}
        charSet='UTF-8'
        data-cookiescriptreport='report'
        onLoad={moveContent}
      />
      <div ref={ref} className='cookie-content' />
    </>
  );
}
