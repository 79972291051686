import { useRouter } from 'next/router';
import Flag from 'react-country-flag';
import classNames from 'classnames';
import { useCallback } from 'react';
import Container from '@amf/shared/components/layout/Container';
import { getLanguageCountry, getLanguageName } from '@amf/shared/utils/languages';

interface NavigationLanguageProps {
  onChange: () => void;
}

export default function NavigationLanguage({ onChange }: NavigationLanguageProps) {
  const { locale, locales, push } = useRouter();
  const setLocale = useCallback(
    async (newLocale: string) => {
      await push('/', '/', { locale: newLocale });
      onChange();
    },
    [onChange, push],
  );

  if (locales.length === 1) {
    return null;
  }

  return (
    <div className='Navigation__language'>
      <Container>
        {locales?.map((code, key) => (
          <a
            key={key}
            onClick={() => setLocale(code)}
            className={classNames('Navigation__languageItem', {
              'Navigation__languageItem--selected': locale === code,
            })}
          >
            <Flag
              className='Navigation__languageItemFlag'
              svg
              countryCode={getLanguageCountry(code)}
            />
            {getLanguageName(code)}
          </a>
        ))}
      </Container>
    </div>
  );
}
