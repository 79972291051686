/* eslint-disable @next/next/no-img-element */

import { PartnerFragment } from '@amf/shared/types/graphql';
import classNames from 'classnames';

type Props = {
  partners: PartnerFragment[];
  className?: string;
};

function FooterPartners({ partners, className }: Props) {
  return (
    <div className={classNames('Footer__partners', className)}>
      {partners.map(partner =>
        partner.imageUrl ? (
          <a
            key={partner.id}
            href={partner.link}
            target='_blank'
            className='Footer__partnersItem'
            rel='noreferrer'
            title={partner.title}
          >
            <div className='Footer__partnersItemImage !inset-4'>
              <img
                src={partner.imageUrl}
                alt={partner.title}
                className='w-full h-full object-contain'
              />
            </div>
          </a>
        ) : null,
      )}
    </div>
  );
}

export default FooterPartners;
