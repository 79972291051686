import { useRouter } from 'next/router';
import { isExternalLink } from '@amf/shared/utils/navigation';

export default function useCurrentPage(path: string, children: string[] = []): boolean {
  const { asPath } = useRouter();

  if (isExternalLink(path)) {
    return false;
  }

  if (asPath.startsWith(`/${path}`) && asPath !== '/') {
    return true;
  }

  for (let child of children) {
    if (child.startsWith('/', 0)) {
      child = child.substring(1);
    }
    if (asPath.startsWith(`/${child}`) && asPath !== '/') {
      return true;
    }
  }

  return false;
}
