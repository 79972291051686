export enum AppLinks {
  homepage = '/',
  newsList = '/novinky',
  league = '/liga',
  matches = '/zapasy',
  match = '/zapas',
  player = '/hrac',
  season = '/sezony',
  team = '/tym',
  table = '/tabulka',
  ground = '/hriste',
  club = '/klub',
  stats = '/statistiky',
  statsLeague = '/statistiky/ligy',
  statsTeam = '/statistiky/tymu',
  referee = '/rozhodci',
  contracts = '/angazma',
  offenses = '/tresty',
  search = '/vyhledavani',
  draws = '/losovani',
}

export enum ExternalLinks {
  playerPortal = 'https://portal.malyfotbal.cz',
}
