import { GraphQLClient } from 'graphql-request';
import { getSdk } from '@amf/shared/types/graphql';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';

export const client = new GraphQLClient(process.env.NEXT_PUBLIC_GRAPH_QL, {
  headers: {
    Identity: process.env.IDENTITY_KEY,
    'X-UNIONS-RESTRICTIONS': 'OFF',
  },
});

export const sdk = getSdk(client);

const wsLink =
  typeof window !== 'undefined'
    ? new WebSocketLink({
        uri: process.env.NEXT_PUBLIC_GRAPH_QL_WS,
        options: {
          reconnect: true,
        },
      })
    : null;

export const apolloClient = new ApolloClient({
  link: wsLink,
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
