import { differenceInYears, format, fromUnixTime } from 'date-fns';
import en from 'date-fns/locale/en-US';
import cs from 'date-fns/locale/cs';

export function getAge(timestamp: number) {
  const date = fromUnixTime(timestamp);
  return differenceInYears(new Date(), date);
}

const formatRelativeCzechLocale = {
  lastWeek: "d.M.yyyy 'v' HH:mm",
  yesterday: "'Včera v' HH:mm",
  today: "'Dnes v' HH:mm",
  tomorrow: "'Zítra v' HH:mm",
  nextWeek: "d.M.yyyy 'v' HH:mm",
  other: "d.M.yyyy 'v' HH:mm",
};

const formatRelativeEnglishLocale = {
  lastWeek: 'd.M.yyyy HH:mm',
  yesterday: "'Yesterday at' HH:mm",
  today: "'Today at' HH:mm",
  tomorrow: "'Tomorrow at' HH:mm",
  nextWeek: 'd.M.yyyy HH:mm',
  other: 'd.M.yyyy HH:mm',
};

export function getDateLocale(locale: string): Locale {
  let dateLocale: Locale = {
    ...cs,
    formatRelative: token => formatRelativeCzechLocale[token],
  };
  if (locale === 'en') {
    dateLocale = {
      ...en,
      formatRelative: token => formatRelativeEnglishLocale[token],
    };
  }

  return dateLocale;
}

export function formatDate(
  date: Date,
  formatString: string,
  options?: { locale?: Locale },
): string {
  // const dtDateOnly = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  const convertedDate = convertTZ(date, 'Europe/Prague');
  return format(convertedDate, formatString, options);
}

function convertTZ(date: Date | string, tzString: string) {
  return new Date(
    (typeof date === 'string' ? new Date(date) : date).toLocaleString('en-US', {
      timeZone: tzString,
    }),
  );
}
