/* eslint-disable @next/next/no-img-element */
import { forwardRef, Ref } from 'react';
import { NavigationItemFragment } from '@amf/shared/types/graphql';
import Button, { ButtonProps } from '@amf/shared/components/button/Button';
import ButtonIcon from '@amf/shared/components/button/ButtonIcon';
import NavigationMenuItem from '@amf/shared/components/navigation/NavigationMenuItem';
import IconList from '@amf/shared/icons/IconList';
import IconClose from '@amf/shared/icons/IconClose';
import Link from 'next/link';

interface NavigationMenuProps {
  buttonRef: Ref<HTMLAnchorElement>;
  navigation: NavigationItemFragment[];
  opened: boolean;
  setOpened: (opened: boolean) => void;
  dropdown?: boolean;
  buttonTitle?: string;
  buttonLink?: string;
  buttonProps?: ButtonProps;
  search?: string;
}

// eslint-disable-next-line react/display-name
const NavigationMenu = forwardRef<HTMLDivElement, NavigationMenuProps>(
  (
    {
      navigation,
      dropdown,
      opened,
      setOpened,
      buttonRef,
      buttonTitle,
      buttonLink,
      buttonProps,
      search,
    },
    ref,
  ) => {
    return (
      <>
        <div className='Navigation__menuOuter' ref={ref}>
          <div className='Navigation__menu'>
            {navigation.map(item => (
              <NavigationMenuItem
                key={item.id}
                item={item}
                className='Navigation__menuItem'
                selectedClassName='Navigation__menuItem--selected'
                onClick={() => setOpened(false)}
              />
            ))}
            {search && (
              <Link href={search}>
                <a className='Navigation__menuSearch'>
                  <i className='bi bi-search' />
                </a>
              </Link>
            )}
          </div>
        </div>
        {buttonLink && (
          <a
            ref={buttonRef}
            className='Navigation__signIn'
            href={buttonLink}
            target='_blank'
            rel='noreferrer'
            onClick={() => setOpened(false)}
          >
            <Button variant='green' {...buttonProps}>
              {buttonTitle}
            </Button>
          </a>
        )}
        <ButtonIcon className='Navigation__menuButton' onClick={() => setOpened(!opened)}>
          {opened ? <IconClose /> : <IconList />}
        </ButtonIcon>
      </>
    );
  },
);

export default NavigationMenu;
