import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react';
import classNames from 'classnames';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'blue'
    | 'blue-dark'
    | 'green'
    | 'blue-outline'
    | 'white-outline'
    | 'dark'
    | 'dark-outline'
    | 'yellow';
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  small?: boolean;
}

// eslint-disable-next-line react/display-name
const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, small, rightIcon, leftIcon, className, children, ...props }, ref) => (
    <button
      ref={ref}
      type='button'
      className={classNames('Button', className, {
        'Button--blue': variant === 'blue',
        'Button--blueDark': variant === 'blue-dark',
        'Button--blueOutline': variant === 'blue-outline',
        'Button--green': variant === 'green',
        'Button--whiteOutline': variant === 'white-outline',
        'Button--dark': variant === 'dark',
        'Button--yellow': variant === 'yellow',
        'Button--darkOutline': variant === 'dark-outline',
        'Button--small': small,
      })}
      {...props}
    >
      {leftIcon && <span className='Button__icon Button__icon--left'>{leftIcon}</span>}
      <span className='Button__content'>{children}</span>
      {rightIcon && <span className='Button__icon Button__icon--right'>{rightIcon}</span>}
    </button>
  ),
);

export default Button;
