function IconAppStore(props: JSX.IntrinsicElements['svg']) {
  return (
    <svg
      width='160'
      height='46'
      viewBox='0 0 160 46'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M158.346 40.6952C158.346 43.1466 156.319 45.1323 153.812 45.1323H5.42475C2.91869 45.1323 0.884521 43.1466 0.884521 40.6952V5.31103C0.884521 2.86079 2.91869 0.868164 5.42475 0.868164H153.811C156.319 0.868164 158.345 2.86079 158.345 5.31103L158.346 40.6952Z'
        fill='#000'
      />
      <path
        d='M153.333 0.921437C156.064 0.921437 158.286 3.08775 158.286 5.75V40.25C158.286 42.9123 156.064 45.0786 153.333 45.0786H5.89744C3.16692 45.0786 0.945064 42.9123 0.945064 40.25V5.75C0.945064 3.08775 3.16692 0.921437 5.89744 0.921437H153.333ZM153.333 2.43107e-06H5.89744C2.65532 2.43107e-06 0 2.58894 0 5.75V40.25C0 43.4111 2.65532 46 5.89744 46H153.333C156.575 46 159.231 43.4111 159.231 40.25V5.75C159.231 2.58894 156.575 2.43107e-06 153.333 2.43107e-06Z'
        fill='#000'
      />
      <path
        d='M35.5356 22.751C35.5014 19.0445 38.6483 17.2413 38.7922 17.1574C37.01 14.6239 34.2476 14.2778 33.2769 14.2502C30.9568 14.0121 28.7064 15.6037 27.5245 15.6037C26.3191 15.6037 24.4991 14.2732 22.5376 14.3123C20.0135 14.3502 17.6522 15.7751 16.3571 17.9877C13.6844 22.4991 15.6777 29.1289 18.2384 32.7755C19.5193 34.5615 21.0161 36.5556 22.9752 36.4854C24.8919 36.4084 25.6078 35.294 27.9208 35.294C30.2126 35.294 30.8849 36.4854 32.8829 36.4406C34.9399 36.4084 36.235 34.6466 37.4711 32.8445C38.9514 30.7975 39.5458 28.7816 39.5694 28.6781C39.5211 28.662 35.5745 27.1934 35.5356 22.751Z'
        fill='white'
      />
      <path
        d='M31.7612 11.8513C32.7921 10.5943 33.4974 8.88428 33.3016 7.14893C31.8096 7.21333 29.9436 8.15518 28.8691 9.38453C27.9185 10.4678 27.0692 12.2434 27.2886 13.9132C28.9647 14.0351 30.6855 13.0887 31.7612 11.8513Z'
        fill='white'
      />
      <path
        d='M63.2736 36.2285H60.595L59.1277 31.7332H54.0276L52.6299 36.2285H50.022L55.075 20.9243H58.1959L63.2736 36.2285ZM58.6854 29.8472L57.3584 25.8509C57.2181 25.4427 56.9551 24.4813 56.567 22.9679H56.5198C56.3653 23.6188 56.1164 24.5802 55.7744 25.8509L54.4711 29.8472H58.6854Z'
        fill='white'
      />
      <path
        d='M76.268 30.5755C76.268 32.4523 75.7479 33.9358 74.7076 35.0248C73.7758 35.9943 72.6187 36.4784 71.2375 36.4784C69.7466 36.4784 68.6757 35.9563 68.0234 34.9121H67.9762V40.7254H65.4616V28.8263C65.4616 27.6464 65.4297 26.4355 65.3684 25.1935H67.5799L67.7203 26.9426H67.7675C68.6061 25.6247 69.8787 24.9669 71.5866 24.9669C72.9218 24.9669 74.0364 25.481 74.9281 26.5102C75.8222 27.5406 76.268 28.8953 76.268 30.5755ZM73.7062 30.6652C73.7062 29.5911 73.4585 28.7056 72.9607 28.0087C72.417 27.2819 71.6869 26.9185 70.7716 26.9185C70.1512 26.9185 69.5874 27.1209 69.0838 27.5199C68.5789 27.9224 68.2487 28.448 68.0942 29.0989C68.0163 29.4025 67.9774 29.6509 67.9774 29.8464V31.6864C67.9774 32.4891 68.2298 33.1664 68.7346 33.7196C69.2395 34.2727 69.8953 34.5487 70.702 34.5487C71.6492 34.5487 72.3863 34.1922 72.9136 33.4815C73.442 32.7697 73.7062 31.8313 73.7062 30.6652Z'
        fill='white'
      />
      <path
        d='M89.286 30.5755C89.286 32.4523 88.7658 33.9358 87.7243 35.0248C86.7937 35.9943 85.6366 36.4784 84.2554 36.4784C82.7646 36.4784 81.6936 35.9563 81.0425 34.9121H80.9953V40.7254H78.4807V28.8263C78.4807 27.6464 78.4488 26.4355 78.3875 25.1935H80.599L80.7394 26.9426H80.7866C81.624 25.6247 82.8967 24.9669 84.6058 24.9669C85.9398 24.9669 87.0544 25.481 87.9484 26.5102C88.8389 27.5406 89.286 28.8953 89.286 30.5755ZM86.7241 30.6652C86.7241 29.5911 86.4752 28.7056 85.9775 28.0087C85.4338 27.2819 84.706 26.9185 83.7896 26.9185C83.168 26.9185 82.6053 27.1209 82.1005 27.5199C81.5957 27.9224 81.2666 28.448 81.1121 29.0989C81.0354 29.4025 80.9953 29.6509 80.9953 29.8464V31.6864C80.9953 32.4891 81.2478 33.1664 81.7502 33.7196C82.255 34.2716 82.9108 34.5487 83.72 34.5487C84.6671 34.5487 85.4043 34.1922 85.9315 33.4815C86.4599 32.7697 86.7241 31.8313 86.7241 30.6652Z'
        fill='white'
      />
      <path
        d='M103.841 31.9373C103.841 33.2391 103.377 34.2983 102.447 35.1159C101.424 36.0095 100.001 36.4557 98.1712 36.4557C96.4821 36.4557 95.1281 36.1383 94.1031 35.5023L94.6858 33.4588C95.7898 34.1097 97.0011 34.4363 98.321 34.4363C99.2681 34.4363 100.005 34.227 100.535 33.8107C101.062 33.3944 101.325 32.8355 101.325 32.1386C101.325 31.5176 101.108 30.9943 100.673 30.57C100.24 30.1456 99.5169 29.7512 98.5073 29.3866C95.7591 28.3873 94.3862 26.9233 94.3862 24.9982C94.3862 23.7401 94.8674 22.7086 95.8311 21.9059C96.7912 21.102 98.0721 20.7007 99.6738 20.7007C101.102 20.7007 102.289 20.9433 103.236 21.4275L102.607 23.4262C101.723 22.957 100.722 22.7224 99.6031 22.7224C98.7184 22.7224 98.0273 22.9351 97.5319 23.3583C97.1132 23.7367 96.9032 24.1978 96.9032 24.7441C96.9032 25.349 97.1426 25.8492 97.6239 26.2425C98.0426 26.6059 98.8034 26.9992 99.9074 27.4236C101.258 27.9537 102.25 28.5736 102.888 29.2843C103.524 29.9927 103.841 30.8793 103.841 31.9373Z'
        fill='white'
      />
      <path
        d='M112.155 27.0335H109.383V32.3913C109.383 33.7541 109.872 34.4349 110.85 34.4349C111.3 34.4349 111.673 34.3969 111.967 34.321L112.037 36.1829C111.542 36.3634 110.889 36.4543 110.081 36.4543C109.088 36.4543 108.312 36.1587 107.752 35.5688C107.194 34.9777 106.913 33.9864 106.913 32.5937V27.0312H105.262V25.1912H106.913V23.1706L109.383 22.4438V25.1912H112.155V27.0335Z'
        fill='white'
      />
      <path
        d='M124.661 30.6205C124.661 32.3168 124.163 33.7094 123.17 34.7985C122.129 35.9197 120.746 36.4786 119.023 36.4786C117.363 36.4786 116.04 35.9416 115.054 34.8675C114.068 33.7934 113.575 32.4375 113.575 30.8034C113.575 29.0933 114.082 27.6926 115.1 26.6036C116.116 25.5134 117.486 24.9683 119.21 24.9683C120.87 24.9683 122.207 25.5053 123.215 26.5806C124.18 27.6236 124.661 28.9703 124.661 30.6205ZM122.052 30.6999C122.052 29.6821 121.829 28.8093 121.377 28.0813C120.85 27.2004 120.097 26.7611 119.12 26.7611C118.109 26.7611 117.341 27.2016 116.814 28.0813C116.362 28.8104 116.139 29.6971 116.139 30.7459C116.139 31.7636 116.362 32.6365 116.814 33.3633C117.358 34.2442 118.117 34.6835 119.098 34.6835C120.058 34.6835 120.811 34.235 121.355 33.3403C121.819 32.5985 122.052 31.7165 122.052 30.6999Z'
        fill='white'
      />
      <path
        d='M132.835 27.3497C132.586 27.3049 132.321 27.2819 132.042 27.2819C131.158 27.2819 130.474 27.6073 129.992 28.2594C129.574 28.8344 129.364 29.5612 129.364 30.4386V36.2289H126.85L126.874 28.6688C126.874 27.3969 126.842 26.2388 126.779 25.1946H128.97L129.062 27.306H129.131C129.397 26.5804 129.815 25.9962 130.389 25.558C130.949 25.1636 131.554 24.9669 132.206 24.9669C132.439 24.9669 132.649 24.983 132.835 25.0118V27.3497Z'
        fill='white'
      />
      <path
        d='M144.081 30.1901C144.081 30.6294 144.052 30.9997 143.989 31.3022H136.445C136.475 32.3924 136.839 33.2261 137.54 33.8011C138.176 34.3152 138.998 34.5728 140.007 34.5728C141.124 34.5728 142.143 34.3991 143.06 34.0507L143.454 35.7527C142.383 36.2081 141.118 36.4346 139.659 36.4346C137.904 36.4346 136.527 35.9309 135.524 34.9247C134.524 33.9184 134.023 32.5672 134.023 30.8721C134.023 29.208 134.488 27.8223 135.421 26.7171C136.398 25.5372 137.718 24.9473 139.379 24.9473C141.01 24.9473 142.245 25.5372 143.083 26.7171C143.747 27.6544 144.081 28.8136 144.081 30.1901ZM141.683 29.5542C141.7 28.8274 141.536 28.1995 141.195 27.6693C140.76 26.9874 140.091 26.647 139.191 26.647C138.369 26.647 137.7 26.9793 137.19 27.6463C136.771 28.1765 136.522 28.8124 136.445 29.553H141.683V29.5542Z'
        fill='white'
      />
      <path
        d='M57.854 11.51C57.854 12.8636 57.4377 13.8825 56.6061 14.5667C55.8359 15.1981 54.7414 15.5143 53.3236 15.5143C52.6207 15.5143 52.0191 15.4844 51.5155 15.4246V8.02896C52.1724 7.92546 52.8801 7.87256 53.6444 7.87256C54.995 7.87256 56.0129 8.15891 56.6993 8.73161C57.4684 9.37906 57.854 10.3048 57.854 11.51ZM56.5507 11.5434C56.5507 10.6659 56.3125 9.99316 55.8359 9.52396C55.3594 9.05591 54.6635 8.82131 53.7471 8.82131C53.3578 8.82131 53.0264 8.84661 52.7516 8.89951V14.5219C52.9037 14.5449 53.1821 14.5552 53.5867 14.5552C54.5326 14.5552 55.2627 14.2988 55.777 13.7859C56.2912 13.273 56.5507 12.5255 56.5507 11.5434Z'
        fill='white'
      />
      <path
        d='M64.7646 12.6922C64.7646 13.526 64.5205 14.2091 64.0322 14.745C63.5203 15.2958 62.8421 15.5707 61.9952 15.5707C61.179 15.5707 60.5291 15.3073 60.0443 14.7783C59.5607 14.2505 59.3189 13.5846 59.3189 12.7819C59.3189 11.9424 59.5678 11.2536 60.0679 10.7188C60.568 10.1841 61.2403 9.91614 62.0872 9.91614C62.9034 9.91614 63.5592 10.1795 64.0558 10.7073C64.5276 11.2202 64.7646 11.8826 64.7646 12.6922ZM63.4825 12.7313C63.4825 12.2311 63.3717 11.8021 63.1511 11.4445C62.8916 11.0121 62.5224 10.7959 62.0424 10.7959C61.5458 10.7959 61.1684 11.0121 60.9089 11.4445C60.6871 11.8021 60.5775 12.238 60.5775 12.7532C60.5775 13.2534 60.6883 13.6824 60.9089 14.04C61.1766 14.4724 61.5494 14.6886 62.0306 14.6886C62.5024 14.6886 62.8727 14.469 63.1393 14.0285C63.3681 13.664 63.4825 13.2316 63.4825 12.7313Z'
        fill='white'
      />
      <path
        d='M74.0307 10.0265L72.291 15.4476H71.1587L70.438 13.0935C70.2552 12.5059 70.1066 11.9217 69.991 11.3421H69.9686C69.8612 11.9378 69.7126 12.5208 69.5215 13.0935L68.756 15.4476H67.6108L65.9748 10.0265H67.2451L67.8738 12.6036C68.0259 13.2131 68.151 13.7939 68.2512 14.3436H68.2736C68.3656 13.8905 68.5178 13.3132 68.7325 12.6151L69.5215 10.0276H70.5288L71.2849 12.5599C71.4677 13.1775 71.6163 13.772 71.7307 14.3447H71.7649C71.8487 13.787 71.9749 13.1924 72.1424 12.5599L72.817 10.0276H74.0307V10.0265Z'
        fill='white'
      />
      <path
        d='M80.4389 15.4477H79.2028V12.3427C79.2028 11.3859 78.8301 10.9075 78.0823 10.9075C77.7155 10.9075 77.4194 11.0386 77.1894 11.3019C76.9618 11.5653 76.8462 11.8758 76.8462 12.2311V15.4465H75.6101V11.5756C75.6101 11.0995 75.5947 10.5832 75.5653 10.0243H76.6516L76.7094 10.8718H76.7436C76.8875 10.6085 77.1021 10.3911 77.384 10.2175C77.719 10.0151 78.0941 9.91272 78.5045 9.91272C79.0235 9.91272 79.4552 10.076 79.7984 10.4038C80.2254 10.8051 80.4389 11.4043 80.4389 12.2001V15.4477Z'
        fill='white'
      />
      <path d='M83.8476 15.4477H82.6127V7.53918H83.8476V15.4477Z' fill='white' />
      <path
        d='M91.125 12.6922C91.125 13.526 90.8808 14.2091 90.3925 14.745C89.8806 15.2958 89.2012 15.5707 88.3556 15.5707C87.5382 15.5707 86.8883 15.3073 86.4047 14.7783C85.9211 14.2505 85.6793 13.5846 85.6793 12.7819C85.6793 11.9424 85.9282 11.2536 86.4283 10.7188C86.9284 10.1841 87.6007 9.91614 88.4464 9.91614C89.2638 9.91614 89.9184 10.1795 90.4161 10.7073C90.8879 11.2202 91.125 11.8826 91.125 12.6922ZM89.8417 12.7313C89.8417 12.2311 89.7308 11.8021 89.5103 11.4445C89.252 11.0121 88.8816 10.7959 88.4027 10.7959C87.905 10.7959 87.5275 11.0121 87.2692 11.4445C87.0475 11.8021 86.9378 12.238 86.9378 12.7532C86.9378 13.2534 87.0487 13.6824 87.2692 14.04C87.537 14.4724 87.9097 14.6886 88.3909 14.6886C88.8627 14.6886 89.2319 14.469 89.4985 14.0285C89.7285 13.664 89.8417 13.2316 89.8417 12.7313Z'
        fill='white'
      />
      <path
        d='M97.1074 15.4476H95.9975L95.9055 14.8232H95.8713C95.4915 15.3211 94.9501 15.5707 94.2472 15.5707C93.7223 15.5707 93.2977 15.4062 92.9781 15.0796C92.6879 14.7829 92.5428 14.4138 92.5428 13.9756C92.5428 13.3132 92.8259 12.8084 93.3956 12.4588C93.9641 12.1092 94.7638 11.9378 95.7935 11.9459V11.8447C95.7935 11.1305 95.409 10.774 94.6388 10.774C94.0903 10.774 93.6067 10.9086 93.1892 11.1754L92.9379 10.3842C93.4546 10.0725 94.0927 9.91614 94.8452 9.91614C96.2983 9.91614 97.0272 10.6636 97.0272 12.1586V14.155C97.0272 14.6967 97.0544 15.1279 97.1074 15.4476ZM95.8241 13.5846V12.7486C94.4607 12.7256 93.7789 13.0901 93.7789 13.8411C93.7789 14.124 93.8568 14.3356 94.016 14.477C94.1752 14.6185 94.3781 14.6886 94.6199 14.6886C94.8912 14.6886 95.1448 14.6047 95.3759 14.4379C95.6083 14.27 95.751 14.0573 95.8041 13.7962C95.8171 13.7376 95.8241 13.6663 95.8241 13.5846Z'
        fill='white'
      />
      <path
        d='M104.131 15.4477H103.034L102.976 14.5772H102.942C102.592 15.2396 101.995 15.5708 101.156 15.5708C100.487 15.5708 99.9286 15.3143 99.4863 14.8014C99.044 14.2885 98.8235 13.6227 98.8235 12.805C98.8235 11.9276 99.0629 11.2169 99.5441 10.6741C100.01 10.1681 100.581 9.91508 101.26 9.91508C102.007 9.91508 102.529 10.16 102.827 10.6511H102.85V7.53918H104.088V13.9872C104.088 14.5151 104.102 15.0015 104.131 15.4477ZM102.85 13.1615V12.2576C102.85 12.1012 102.838 11.9747 102.816 11.8781C102.746 11.5883 102.597 11.3445 102.369 11.1479C102.139 10.9512 101.862 10.8523 101.542 10.8523C101.081 10.8523 100.72 11.0306 100.455 11.3882C100.192 11.7459 100.058 12.2024 100.058 12.7602C100.058 13.2961 100.185 13.7308 100.438 14.0654C100.706 14.4219 101.067 14.6002 101.519 14.6002C101.924 14.6002 102.249 14.4518 102.495 14.154C102.733 13.8791 102.85 13.5479 102.85 13.1615Z'
        fill='white'
      />
      <path
        d='M114.703 12.6922C114.703 13.526 114.459 14.2091 113.97 14.745C113.459 15.2958 112.781 15.5707 111.933 15.5707C111.118 15.5707 110.469 15.3073 109.983 14.7783C109.499 14.2505 109.257 13.5846 109.257 12.7819C109.257 11.9424 109.506 11.2536 110.006 10.7188C110.506 10.1841 111.179 9.91614 112.027 9.91614C112.842 9.91614 113.499 10.1795 113.994 10.7073C114.466 11.2202 114.703 11.8826 114.703 12.6922ZM113.422 12.7313C113.422 12.2311 113.311 11.8021 113.091 11.4445C112.83 11.0121 112.462 10.7959 111.981 10.7959C111.485 10.7959 111.108 11.0121 110.847 11.4445C110.625 11.8021 110.516 12.238 110.516 12.7532C110.516 13.2534 110.627 13.6824 110.847 14.04C111.115 14.4724 111.488 14.6886 111.969 14.6886C112.441 14.6886 112.812 14.469 113.079 14.0285C113.306 13.664 113.422 13.2316 113.422 12.7313Z'
        fill='white'
      />
      <path
        d='M121.349 15.4477H120.114V12.3427C120.114 11.3859 119.742 10.9075 118.993 10.9075C118.626 10.9075 118.33 11.0386 118.101 11.3019C117.872 11.5653 117.758 11.8758 117.758 12.2311V15.4465H116.52V11.5756C116.52 11.0995 116.506 10.5832 116.477 10.0243H117.562L117.62 10.8718H117.654C117.799 10.6085 118.014 10.3911 118.294 10.2175C118.631 10.0151 119.004 9.91272 119.416 9.91272C119.934 9.91272 120.366 10.076 120.709 10.4038C121.137 10.8051 121.349 11.4043 121.349 12.2001V15.4477Z'
        fill='white'
      />
      <path
        d='M129.668 10.9292H128.307V13.5627C128.307 14.232 128.549 14.5667 129.028 14.5667C129.25 14.5667 129.434 14.5483 129.579 14.5103L129.61 15.4246C129.366 15.5143 129.045 15.5591 128.65 15.5591C128.162 15.5591 127.782 15.4142 127.507 15.1244C127.231 14.8346 127.095 14.347 127.095 13.6628V10.9292H126.282V10.0265H127.095V9.03289L128.306 8.67639V10.0253H129.667V10.9292H129.668Z'
        fill='white'
      />
      <path
        d='M136.212 15.4477H134.975V12.3657C134.975 11.394 134.602 10.9075 133.855 10.9075C133.282 10.9075 132.891 11.1893 132.676 11.7528C132.639 11.8712 132.618 12.0161 132.618 12.1863V15.4466H131.383V7.53918H132.618V10.8063H132.642C133.031 10.2118 133.589 9.91508 134.312 9.91508C134.824 9.91508 135.247 10.0784 135.583 10.4061C136.002 10.8144 136.212 11.4216 136.212 12.2243V15.4477Z'
        fill='white'
      />
      <path
        d='M142.962 12.4802C142.962 12.6964 142.946 12.8781 142.916 13.0265H139.209C139.226 13.5624 139.403 13.9706 139.746 14.2535C140.06 14.5065 140.464 14.633 140.959 14.633C141.508 14.633 142.008 14.5479 142.459 14.3766L142.652 15.2138C142.125 15.4369 141.504 15.5484 140.786 15.5484C139.925 15.5484 139.247 15.3012 138.756 14.8067C138.263 14.3122 138.019 13.6486 138.019 12.816C138.019 11.9984 138.247 11.3176 138.705 10.7748C139.184 10.1952 139.832 9.9054 140.649 9.9054C141.449 9.9054 142.056 10.1952 142.467 10.7748C142.798 11.2348 142.962 11.804 142.962 12.4802ZM141.783 12.1686C141.792 11.8109 141.711 11.5027 141.543 11.2428C141.329 10.9082 141.002 10.7403 140.56 10.7403C140.156 10.7403 139.827 10.9036 139.576 11.2313C139.371 11.4924 139.249 11.804 139.209 12.1686H141.783Z'
        fill='white'
      />
    </svg>
  );
}

export default IconAppStore;
