import { ReactNode } from 'react';
import Copyright from '@amf/shared/components/layout/Copyright';

interface LayoutProps {
  children: ReactNode;
  footer: ReactNode;
}

export default function Layout({ children, footer }: LayoutProps) {
  return (
    <div className='Layout'>
      <div className='Layout__content'>{children}</div>
      {footer}
      <Copyright />
    </div>
  );
}
