import { ButtonHTMLAttributes, forwardRef } from 'react';
import classNames from 'classnames';

interface ButtonIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'white' | 'dark';
}

// eslint-disable-next-line react/display-name
const ButtonIcon = forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ className, variant, children, ...props }, ref) => (
    <button
      ref={ref}
      type='button'
      className={classNames(
        'ButtonIcon',
        {
          'ButtonIcon--dark': variant === 'dark',
        },
        className,
      )}
      {...props}
    >
      {children}
    </button>
  ),
);

export default ButtonIcon;
