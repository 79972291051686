/* eslint-disable @next/next/no-css-tags */
import Head from 'next/head';
import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useTranslate } from '@tolgee/react';
import CookieScript from '@amf/shared/components/utils/CookieScript';
import { useEffect, useState } from 'react';
import SmartBanner from 'react-smartbanner';
import { OpenGraphMedia } from 'next-seo/lib/types';

import { OgImageType, ogImageUrl } from './og/OgImage';

import 'react-smartbanner/dist/main.css';

export interface MetaProps {
  title: string;
  description: string | null;
  image?: string | null;
  type?: string | null;
  cookieKey?: string | null;
  noApp?: boolean;
}

export default function Meta({ cookieKey, title, description, image, type, noApp }: MetaProps) {
  const { asPath, locale } = useRouter();
  const [domain, setDomain] = useState<string | null>(null);
  const { t } = useTranslate();

  const images: OpenGraphMedia[] = image
    ? [{ url: image, secureUrl: image, height: 628, width: 1200, alt: title, type: 'image/png' }]
    : [
        {
          secureUrl: ogImageUrl(locale, OgImageType.default, { title }),
          url: ogImageUrl(locale, OgImageType.default, { title }),
          height: 628,
          width: 1200,
          alt: title,
          type: 'image/png',
        },
      ];

  useEffect(() => {
    setDomain(document.location.toString());
  }, []);

  return (
    <>
      <CookieScript cookieKey={cookieKey} />
      <Head>
        <title>{title}</title>
        <link rel='canonical' href={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath}`} />
        <link href='https://use.typekit.net/brg7ciq.css' rel='stylesheet' />
        <link rel='stylesheet' type='text/css' href='/nprogress.css' />
        {!noApp && (
          <>
            <meta
              name='apple-itunes-app'
              content={`app-id=1665660869, app-argument=${domain ?? asPath}`}
            />
            <meta name='google-play-app' content='app-id=com.amf' />
            <link
              rel='android-touch-icon'
              href='https://play-lh.googleusercontent.com/C--hh9dFQQpI3Oi7izq97Nu1s4paeWEHWcBFVc9mXgPay5pjoQ2RS0dnzu9LFYWjFcc=w240-h480-rw'
            />
          </>
        )}
      </Head>
      <NextSeo
        title={title}
        description={description}
        openGraph={{
          description,
          title,
          images,
          type: type ?? 'website',
          url: `${process.env.NEXT_PUBLIC_BASE_URL}${asPath}`,
        }}
      />
      {!noApp && (
        <SmartBanner
          title={'Malý Fotbal'}
          storeText={{ android: 'K dispozici na Google Play' }}
          price={{ android: 'Zdarma' }}
          button='Stáhnout'
        />
      )}
    </>
  );
}
