import Link from 'next/link';
import { ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { NavigationItem } from '@amf/shared/types/graphql';
import Container from '@amf/shared/components/layout/Container';
import NavigationLanguage from '@amf/shared/components/navigation/NavigationLanguage';
import NavigationMenu from '@amf/shared/components/navigation/NavigationMenu';
import { useWindowSize } from '@amf/shared/hooks/useWindowSize';
import { useRouter } from 'next/router';
import { ButtonProps } from '@amf/shared/components/button/Button';

interface NavigationProps {
  navigation: NavigationItem[];
  variant: 'blue' | 'yellow' | 'white';
  brand: ReactNode;
  buttonTitle?: string;
  buttonLink?: string;
  buttonProps?: ButtonProps;
  search?: string;
}

export default function Navigation({
  navigation,
  brand,
  variant,
  buttonTitle,
  buttonLink,
  buttonProps,
  search,
}: NavigationProps) {
  const ref = useRef<HTMLDivElement>(null);
  const brandRef = useRef<HTMLAnchorElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLAnchorElement>(null);
  const [opened, setOpened] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const [brandWidth, setBrandWidth] = useState(0);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [mobile, setMobile] = useState(false);
  const { width } = useWindowSize();
  const { locales } = useRouter();

  useEffect(() => {
    setMenuWidth(menuRef.current?.clientWidth ?? 0);
    setButtonWidth(buttonRef.current?.clientWidth ?? 0);
    setBrandWidth(brandRef.current?.clientWidth ?? 0);
    return () => document.body.classList.remove('withOverlay');
  }, [menuRef, buttonRef, brandRef]);

  useEffect(() => {
    if (opened) {
      document.body.classList.add('withOverlay');
    } else {
      document.body.classList.remove('withOverlay');
    }
  }, [opened]);

  useEffect(() => {
    // paddingLeft + brandWidth + gap + menuWidth + gap + button + paddingRight + magic
    const requiredWidth = 32 + brandWidth + 24 + menuWidth + 24 + buttonWidth + 32 + 24;
    const containerWidth = containerRef.current?.clientWidth ?? 0;

    setMobile(requiredWidth > containerWidth);
  }, [width, menuWidth, buttonWidth, brandWidth, containerRef]);

  return (
    <div
      ref={ref}
      className={classNames(
        'Navigation',
        {
          'Navigation--dropdown': mobile,
          'Navigation--dropdownOpened': opened && mobile,
          'Navigation--noLanguage': locales.length === 1,
        },
        `Navigation--${variant}`,
      )}
    >
      <NavigationLanguage onChange={() => setOpened(false)} />
      <div className='Navigation__main'>
        <Container ref={containerRef} className='Navigation__mainContainer'>
          <Link href='/'>
            <a ref={brandRef} className='Navigation__brand' onClick={() => setOpened(false)}>
              {brand}
            </a>
          </Link>
          <NavigationMenu
            ref={menuRef}
            buttonRef={buttonRef}
            navigation={navigation}
            opened={opened}
            setOpened={setOpened}
            dropdown={mobile}
            buttonTitle={buttonTitle}
            buttonLink={buttonLink}
            buttonProps={buttonProps}
            search={search}
          />
        </Container>
      </div>
    </div>
  );
}
